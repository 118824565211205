import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";

import { IoIosSearch } from "react-icons/io";
import { useSelector } from "react-redux";

import { CiFilter } from "react-icons/ci";
import { LGAs } from "../../constants/data";
import { TfiClose } from "react-icons/tfi";
import Spinner from "../generic/Spinner";
import { onGetAllcertificates } from "../../Utils/Servicess/user";
import { MdCancel } from "react-icons/md";
import { FaEnvelopesBulk } from "react-icons/fa6";

// interface InputFieldProps {
//    placeholder: string;
//    onchange:any;
//    value:string;
//   }

export default ({
  placeholder,
  value,
  onchange,
  setrenderedschools,
  schools,
  psyments,
}) => {
  const [focused, setfocused] = useState(false);
  const [searchitemss, setsearchitemss] = useState([]);
  const [showsearch, setshowsearch] = useState(false);
  const [loading, setLoading] = useState(false);
  var authuser = useSelector((state) => state.userStore);

  const [accred_schools, setaccred_schools] = useState([]);
  const [searchleveles, setserachleveles] = useState([]);
  var userlgas = authuser.lgas.split(" , ");
  const schooltype = [
    "Private school",
    "State government school",
    "Federal government school",
    "Force school",
  ];
  const accreditaion = ["accredited", "unaccredited"];
  const category = [
    "Nusery",
    "Primary",
    "Secondary",
    "Nusery,Primary",
    "Nusery,Primary,Secondary",
  ];

  useEffect(() => {
    if (searchitemss.length == 0) {
      setrenderedschools(schools);
    } else {
      searchitemss.map((obj) => {
        switch (obj.group) {
          case "schooltype":
            setrenderedschools((prev) =>
              prev.filter((it) =>
                it?.schooltype?.toLowerCase().includes(obj.name?.toLowerCase())
              )
            );
            break;
          case "accred":
            if (obj.name == "accredited") {
              setrenderedschools((prev) =>
                prev.filter(
                  (it) =>
                    accred_schools.includes(it.schoolname) ||
                    it.schooltype.includes("State Government School")
                )
              );
            } else if (obj.name == "unaccredited") {
              setrenderedschools((prev) =>
                prev.filter(
                  (it) =>
                    !(
                      accred_schools.includes(it.schoolname) ||
                      it.schooltype.includes("State Government School")
                    )
                )
              );
            }

            break;
          case "lock":
            if (obj.name == "Locked") {
              setrenderedschools((prev) => prev.filter((it) => it.locked));
            } else if (obj.name == "Not Locked") {
              setrenderedschools((prev) => prev.filter((it) => !it.locked));
            }

            break;
          case "cat":
            setrenderedschools((prev) =>
              prev.filter(
                (it) =>
                  it?.schoolcategories?.toLowerCase() == obj.name?.toLowerCase()
              )
            );
            break;
          case "lga":
            setrenderedschools((prev) =>
              prev.filter((it) =>
                it?.address
                  ?.toLowerCase()
                  .includes(`${" "}${obj.name.toLowerCase()}${" osun state"}`)
              )
            );

            break;
          case "pay":
            if (obj.name == "Paid") {
              setrenderedschools((prev) =>
                prev.filter((it) => psyments.includes(it.schoolname))
              );
            } else if (obj.name == "UnPaid") {
              setrenderedschools((prev) =>
                prev.filter((it) => !psyments.includes(it.schoolname))
              );
            }
            break;
          case "rate":
            setrenderedschools((prev) => []);
            break;
          case "staffs":
            setrenderedschools((prev) => []);
            break;
          case "students":
            setrenderedschools((prev) => []);
            break;
        }
      });
    }
  }, [searchitemss]);

  useEffect(() => {
    const getaccredschools = async () => {
      await Promise.all(
        schools.map(async (item) => {
          const postdata = { school: item.schoolname };
          console.log(postdata, "certificates");
          setLoading(true);
          var res = await onGetAllcertificates(postdata);
          setLoading(false);
          if (res.certicates) {
            if (
              res.certicates?.some(
                (i) =>
                  i.title.includes("CAC Certificate") &&
                  i.title.includes("verified")
              )
            ) {
              //Accredited
              setaccred_schools((prev) => prev.concat([item.schoolname]));
            }
          }
        })
      );
    };

    getaccredschools();
  }, [schools]);

  useEffect(() => {
    console.log(accred_schools);
  }, [accred_schools]);

  const Filteritem = ({ item, clicked }) => (
    <div
      onClick={() => {
        if (searchitemss.includes(item)) {
          setsearchitemss((prev) => prev.filter((k) => k != item));
        } else {
          clicked();
          setsearchitemss((prev) => prev.concat([item]));
        }

        setshowsearch(false);
      }}
      className={`px-3 py-1 cursor-pointer inline text-sm ${
        searchitemss.some((gh) => gh.name == item.name)
          ? " bg-primary text-white "
          : "  bg-faint "
      }  my-2  mx-1 rounded  min-w-[500px] !capitalize `}
    >
      {item.name}
    </div>
  );

  const SelectedItem = ({ item }) => (
    <div className="px-3 py-2 bg-faint cursor-pointer inline text-sm  my-2  mx-1 rounded    flex-wrap  truncate">
      <TfiClose
        onClick={() => {
          setsearchitemss((prev) => prev.filter((k) => k != item));
        }}
        className=" text-black inline "
      />
      {item.name}
    </div>
  );
  const searchcatheadstyle =
    " text-sm text-black text-gray-500 font-medium mt-4 mb-2 font-semibold ";
  const containerstyle = "my-2  w-full";

  return (
    <div
      className=" flex flex-col w-full relative z-[300000]"
      onClick={() => {}}
    >
      <Spinner loading={loading} />

      <div
        className={`flex justify-center  relative items-center border-2 ${
          focused ? " border-primary  " : " border-gray-200"
        }  bg-white rounded-lg  `}
      >
        <IoIosSearch size={20} className="ml-4 text-textbg" />
        <input
          type={"text"}
          value={value}
          onClick={() => {
            setshowsearch(true);
          }}
          placeholder={placeholder}
          onChange={(e) => {
            onchange(e.target.value);
          }}
          className={` custom-outline  block bg-white text-xs py-3 custom-outline	px-4 rounded-lg   w-full shadow-xs text-textbg `}
        />
        {/* Show list of selected filter item */}
        {searchitemss.map((h) => (
          <SelectedItem item={h} />
        ))}
      </div>

      {showsearch && (
        <div className=" p-6 relatvie shadow bg-white w-full top-12 left-0 h-[500px] overflow-y-scroll">
          <div className="w-full flex justify-end items-center">
            <MdCancel
              onClick={() => {
                setshowsearch(false);
              }}
              size={30}
              className="text-primary  "
            />
          </div>

          <div className={containerstyle}>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " /> School Type
            </p>{" "}
            <hr />
            <div className={containerstyle}>
              {schooltype.map((item) => (
                <Filteritem
                  clicked={() => {}}
                  item={{ group: "schooltype", name: item }}
                />
              ))}
            </div>
          </div>
          <div>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " />
              Accreditation Status Type
            </p>{" "}
            <hr />
            <div className={containerstyle}>
              <Filteritem
                clicked={() => {}}
                item={{ group: "accred", name: "accredited" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "accred", name: "unaccredited" }}
              />
            </div>
          </div>
          <div className={containerstyle}>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " />
              School Category
            </p>{" "}
            <hr />
            <div className={containerstyle}>
              {category.map((item) => (
                <Filteritem
                  clicked={() => {}}
                  item={{ group: "cat", name: item }}
                />
              ))}
            </div>
          </div>
          <div className={containerstyle}>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " />
              LGA
            </p>
            <hr />
            <div className={`${containerstyle} `}>
              {userlgas.map((item) => (
                <div className=" m-2     inline-block my-4">
                  <Filteritem
                    clicked={() => {}}
                    item={{ group: "lga", name: item.toLowerCase() }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " /> Locked Status
            </p>
            <hr />
            <div className={containerstyle}>
              <Filteritem
                clicked={() => {}}
                item={{ group: "lock", name: "Locked" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "lock", name: "Not Locked" }}
              />
            </div>
          </div>

          <div>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " /> QA Assessment Fee Payment{" "}
            </p>
            <hr />
            <div className={containerstyle}>
              <Filteritem
                clicked={() => {}}
                item={{ group: "pay", name: "Paid" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "pay", name: "UnPaid" }}
              />
            </div>
          </div>
          <div>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " />
              Rating
            </p>
            <hr />
            <div className={containerstyle}>
              <Filteritem
                clicked={() => {}}
                item={{ group: "rate", name: "1 Star" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "rate", name: "2 Star" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "rate", name: "3 Star" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "rate", name: "4 Star" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "rate", name: "5 Star" }}
              />
            </div>
          </div>
          <div>
            <p className={searchcatheadstyle}>
              <CiFilter className="inline " />
              No of Staff/Students
            </p>
            <hr />
            <div className=" m-2 inline-block my-4">
              <Filteritem
                clicked={() => {}}
                item={{
                  group: "staffs",
                  name: "Highest no of teaching staffs",
                }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "staffs", name: "Lowest no of teaching staffs" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "students", name: "Highest no of students" }}
              />
              <Filteritem
                clicked={() => {}}
                item={{ group: "students", name: "Lowest no of students" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
